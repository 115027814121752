/*
import Map from "@aobtravel/falco-webkit/src/components/molecules/map/map";
import { newComponent } from "@aobtravel/falco-webkit/src/support";

$(function() {
  $("a[data-ajax='true']").on("click", () => {
    setTimeout(() => {
      const mapTarget = document.getElementById("map-canvas");
      if (typeof mapTarget !== "undefined") {
        const lat = mapTarget.getAttribute("data-lat");
        const lng = mapTarget.getAttribute("data-lng");

        newComponent(mapTarget, Map, { lat, lng });
      }
    }, 500);
  });
});
*/

$(document).ready(function() {
  if ($('.flight-first').length) {
    $('.flight-first').html(formatDate(0));
  }

  if ($('.flight-second').length) {
    $('.flight-second').html(formatDate(1));
  }

   $('.direct').click(function() {
    if ($(this).attr('class').includes('active')) {
      $(this).toggleClass('active');
      for(let i = 0; i < gon.search_parameters.legs.length; i++) {
        $("#filter_stops_" + i + "_2").click();
      }
    } else {
      for(let i = 0; i < gon.search_parameters.legs.length; i++) {
        $("#filter_stops_" + i + "_0").click();
      }
      $('.one-stop').removeClass('active');
      $(this).addClass('active');
    }
  });

    $('.one-stop').click(function() {
    if ($(this).attr('class').includes('active')) {
      $(this).toggleClass('active');
      for(let i = 0; i < gon.search_parameters.legs.length; i++) {
        $("#filter_stops_" + i + "_2").click();
      }
    } else {
      for(let i = 0; i < gon.search_parameters.legs.length; i++) {
        $("#filter_stops_" + i + "_1").click();
      }
      $('.direct').removeClass('active');
      $(this).addClass('active');
    }
  });

  $(".arrow").each(function () {
    this.addEventListener("click", function() {
      newSearch(this.id);
    });
  });

  $(".ui-listview").each(function () {
    this.addEventListener("click", function() {
      if ($(".search_bags").length > 0) {
        $(".search_bags")[0].addEventListener("click", function() {
          newSearch(0);
        });
      }
    });
  });


});


function newSearch(buttonId) {
  var onlyWithBags = gon.search_parameters.only_with_bags
  if (buttonId == 0) {
    onlyWithBags = 1
  }
  $.ajax({
    url: '/new_search',
    type: 'POST',
    data: { search: { mobile: 1,
                      type: gon.search_parameters.type,
                      cabin: gon.search_parameters.cabin,
                      adults: gon.search_parameters.adults,
                      airline: gon.search_parameters.airline,
                      no_children: (childrenCount() + infantCount()),
                      youths: youthCount(),
                      child: child(),
                      youth: youth(),
                      max_one_stop: gon.search_parameters.max_one_stop,
                      only_with_bags: onlyWithBags,
                      only_direct_flights: gon.search_parameters.only_direct_flights,
                      legs: createLegs(buttonId)
                    }
          },
    success: function(response){
      location.href = document.location.origin + response.url
    }
  });
};

function infantCount() {
  if (gon.search_parameters.infants) {
    return gon.search_parameters.infants.length;
  }
  return 0;
}

function youthCount() {
  if (gon.search_parameters.youths) {
    return gon.search_parameters.youths.length;
  }
  return 0;
};


function childrenCount() {
  if (gon.search_parameters.children) {
    return gon.search_parameters.children.length;
  }
  return 0;
};

function child() {
  var childHash = { 1: 6, 2: 6, 3: 6, 4: 6, 5: 6, 6: 6, 7: 6, 8: 6 }
  var infants = infantCount();
  if (infants > 0) {
    for (i=0; i < infants; i++) {
      childHash[i+1] = gon.search_parameters.infants[i];
    }
  }
  return childHash;
}

function youth() {
  var youthHash = { 1: 18, 2: 18, 3: 18, 4: 18, 5: 18, 6: 18, 7: 18, 8: 18 }
  var youths = youthCount();
  if (youths > 0) {
    for (i=0; i < youths; i++) {
      youthHash[i+1] = gon.search_parameters.youths[i];
    }
  }
  return youthHash;
}

function createLegs(buttonId) {
  if (gon.search_parameters.type == 'oneway') {
    var leg = { 0: { origin_id: gon.search_parameters.legs[0][0], destination_id: gon.search_parameters.legs[0][1], date: gon.search_parameters.legs[0][2] } }
  }
  else {
    var leg = {
                0: { origin_id: gon.search_parameters.legs[0][0], destination_id: gon.search_parameters.legs[0][1], date: gon.search_parameters.legs[0][2] },
                1: { origin_id: gon.search_parameters.legs[1][0], destination_id: gon.search_parameters.legs[1][1], date: gon.search_parameters.legs[1][2] }
              }
  }

  if (buttonId == 1) {
    leg['0'].date = setDate(1, leg['0'].date);
  }
  else if (buttonId == 2) {
    leg['0'].date = setDate(2, leg['0'].date);
  }
  else if (buttonId == 3) {
    leg['1'].date = setDate(1, leg['1'].date);
  }
  else if (buttonId == 4) {
    leg['1'].date = setDate(2, leg['1'].date);
  }
  else {
  }
  return leg;
};

function setDate(addOrDeduct, date) {
  var date = new Date(date);
  if (addOrDeduct == 1) {
    date.setDate(date.getDate() - 1);
  }
  else {
    date.setDate(date.getDate() + 1);
  }
  date = date.toISOString() .split("T")[0];
  return date;
};

function formatDate(val) {
  var date = new Date(gon.search_parameters.legs[val][2]);
  var days = gon.common.day_names
  var months = gon.common.month_names.filter(Boolean);
  return days[date.getDay()] + " " + date.getDate() + " " + months[date.getMonth()] + ".";
}
